import { Elm } from './Designbook.elm';
import * as serviceWorker from './serviceWorker';
import 'elm-keyboard-shortcut';

Elm.Designbook.init({
    node: document.getElementById('root'),
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
